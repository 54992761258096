<template>
  <section>
    <div class="content-header no-select">
      <span style="margin-left: 10px">
        <span class="hidden-xs hidden-sm"> {{ $t("configurations") }} </span>
        <span class="hidden-md hidden-lg" v-if="currentOption">
          <i :class="currentOption.icon || ''"></i>
          {{ currentOption.title }}
        </span>
      </span>
    </div>
    <div class="row content-row">
      <div
        class="col-sm-3 col-lg-2 hidden-sm hidden-xs left-column"
        ref="menu"
        v-if="expanded"
      >
        <div class="box box-solid side-menu">
          <div class="box-body no-padding" style="">
            <div v-if="!menuOptions" class="text-center padding-20">
              <Spin />
            </div>
            <ul class="nav nav-pills nav-stacked">
              <li
                v-for="(option, ix) in menuOptions"
                :key="ix"
                :class="{
                  active: option.id == active
                }"
                :title="option.title"
              >
                <a href="" @click.stop.prevent="active = option.id">
                  <i :class="option.icon"></i>
                  <span v-if="expanded" class="hidden-sm hidden-xs">
                    {{ option.title }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <!-- /.box-body -->
        </div>
      </div>
      <div
        :class="[expanded ? 'col-md-9' : 'col-xs-12', 'main-content']"
        ref="main"
      >
        <div v-if="!menuOptions" class="box box-solid">
          <div class="box-body text-center padding-20">
            <Spin />
          </div>
        </div>
        <div class="box box-solid" v-if="active == 'profile'">
          <div class="box-header with-border header hidden-xs hidden-sm">
            <i class="fa fa-user"></i> {{ $t("user_profile") }}
          </div>
          <div class="box-body">
            <UserProfileForm />
          </div>
        </div>
        <div class="box box-solid" v-if="active == 'security'">
          <div class="box-header with-border header hidden-xs hidden-sm">
            <i class="fa fa-lock"></i> {{ $t("security") }}
          </div>
          <div class="box-body">
            <UserSecurityForm />
          </div>
        </div>
        <div class="box box-solid" v-if="active == 'preferences'">
          <div class="box-header with-border header hidden-xs hidden-sm">
            <i class="fa fa-star"></i> {{ $t("preferences") }}
          </div>
          <div class="box-body">
            <UserPreferencesForm :sidebar="false" />
          </div>
        </div>
        <div class="box box-solid" v-if="active == 'contract_settings'">
          <div class="box-header with-border header hidden-xs hidden-sm">
            <i class="fa fa-cogs"></i> {{ $t("contract_settings") }}
          </div>
          <div class="box-body">
            <ContractSettings />
          </div>
        </div>
        <div class="box box-solid" v-if="active == 'extended_properties'">
          <div class="box-header with-border header hidden-xs hidden-sm">
            <i class="fa fa-key"></i> {{ $t("extended_properties") }}
          </div>
          <div class="box-body">
            <FormConnectorExtendedProperties />
          </div>
        </div>
        <div class="box box-solid" v-if="active == 'access_key'">
          <div class="box-header with-border header hidden-xs hidden-sm">
            <i class="fa fa-key"></i> {{ $tc("access_key", 2) }}
          </div>
          <div class="box-body">
            <FormContractTokens />
          </div>
        </div>
        <UserPlanPanel
          v-if="active == 'billing'"
          :class="{'md-view': meetWidthThreshold && expanded}"
          :tab="innerTab"
        />
      </div>
    </div>
  </section>
</template>

<script>
import UserProfileForm from "@/components/user-profile-form";
import UserPlanPanel from "@/components/user-plan/user-plan-panel.vue";
import UserPreferencesForm from "@/components/control-sidebar/property-editors/user-preferences-form.vue";
import FormConnectorExtendedProperties from "@/components/registration/form-connector-extended-properties.vue";
import FormContractTokens from "@/components/registration/form-contract-tokens.vue";
import ContractSettings from "@/components/contract-settings.vue";
import MixinMobile from "@/project/mixin-mobile";
import UserSecurityForm from "@/components/user-security-form";
import Spin from "@/components/spin";
import {Menu} from "@/components/profile-dropdown-menu.vue";
export default {
  name: "DashboardSettings",
  mixins: [MixinMobile],
  props: {
    initial: {
      type: String,
      default: "profile",
      required: false
    },
    innerTab: {
      type: String
    }
  },
  components: {
    UserProfileForm,
    UserPreferencesForm,
    UserPlanPanel,
    FormConnectorExtendedProperties,
    FormContractTokens,
    ContractSettings,
    UserSecurityForm,
    Spin
  },
  data() {
    return {
      ready: false,
      iActive: "",
      expanded: !this.$utils.isMobile(),
      containerHeight: "auto",
      widthThreshold: {min: 991, max: 1199}
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    active: {
      set(value) {
        let vlr = value && value.replace(/\-/g, "_");
        if (!vlr) return;
        let option = value && this.menuOptions.find(({id}) => id == vlr);
        if (option) {
          this.iActive = option.id;
          if (option.path != this.$route.path) {
            this.$router.push(option.path);
          }
          this.$root.$emit("controlSidebar:setContent", null);
        }
      },
      get() {
        return this.iActive;
      }
    },
    menuOptions() {
      return (this.ready && this.menu.options()) || [];
    },
    currentOption() {
      return (this.menuOptions || []).find(({id}) => id == this.active);
    }
  },
  watch: {
    contract: {
      handler(n, o) {
        if (!this.menu && n) {
          this.menu = new Menu(this);
          this.ready = true;
          this.active = this.initial || "profile";
        }
      },
      immediate: true,
      deep: true
    },
    $route(to) {
      let option =
        this.active && this.menuOptions.find(({id}) => id == this.active);
      if (!option && option.path == to.path) return;
      option = this.menuOptions.find(({path}) => path == to.path);
      if (option) this.active = option.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.left-column {
  margin-right: -27px;
}

.box {
  margin-bottom: 0;
}

.box-header {
  font-size: 20px;
}

.nav {
  overflow-x: auto;
}

.nav > li > a {
  padding: 10px 5px;
  font-size: 10pt;
  white-space: normal;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #337ab7;
  background-color: initial;
}

div.content-header {
  position: relative;
  padding: 7px 0;
  margin-bottom: 3px;
  font-size: 14pt;
  background: white;
  border-bottom: 1px solid #f4f4f4;
}

.content-row {
  display: flex;
  align-items: stretch;
  min-height: calc(100dvh - 100px);
}

.main-content {
  flex-grow: 1;
}

.main-content > .box {
  height: 100%;
}

.toggle {
  background-color: transparent;
  background-image: none;
  color: #666;
  padding: 0 6px;
  font-family: fontAwesome;
}

.toggle::before {
  content: "\f0c9";
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.side-menu {
  height: 100%;
}

.padding-20 {
  padding: 20px;
}

@media (min-width: 1400px) {
  .container {
    width: 1376px;
  }
}

.dropdown-toggle {
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 1;
}
.dropdown-toggle:active {
  outline-width: 0;
  background-color: transparent;
  box-shadow: none;
}

.box-header {
  padding: 5px 10px;
}

ul > li > a > i {
  min-width: 16px;
}

.open > .dropdown-menu {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}
</style>
